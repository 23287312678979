<template>
  <div>
    <RecoveryPassword />
  </div>
</template>
<script>
import RecoveryPassword from '@/components/system/Auth/ForgotPassword/recoveryPassword.vue'
export default {
  components: {
    RecoveryPassword
  },
}
</script>
