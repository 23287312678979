<template>
  <div :class="$style.auth">
    <MsgEmailsent v-if="showMessage" />
    <div class="pt-5 pb-5 d-flex align-items-end mt-auto">
      <img src="resources/images/logo_abda.png" alt="abdalogo" />
    </div>
    <div class="pl-5 pr-5 pt-5 pb-5 bg-white text-center" :class="$style.container">
      <div class="text-dark font-size-30 mb-4">Recuperar Senha</div>
      <b-form class="mb-4">
       <b-form-group
          label="E-mail:"
          label-for="email"
          invalidFeedback="Email inválido"
          :state="!veeErrors.first('email')"
        >
          <b-form-input
            v-model="sent.email"
            id="email"
            type="email"
            placeholder="seu@email.com"
            name="email"
            v-validate="'required|email'"
            :state="!veeErrors.first('email')"
          ></b-form-input>
        </b-form-group>
        <button
          type="button"
          class="text-center btn btn-success w-100 font-weight-bold font-size-18 mt-3"
          :disabled="sent.email ? !!veeErrors.first('email'): true"
          @click="onRequestRecover"
        >Recuperar minha senha</button>
      </b-form>

      <!-- <b-form-group
        label="SMS"
        label-for="phone"
      >
        <b-form-input
          v-model="sent.phone"
          id="phone"
          type="tel"
          placeholder="(##) #####-####"
          name="phone"
          data-vv-as="SMS"
          v-validate="{ required: false }"
          :state="validateState('phone')"
          :disabled="true"
        ></b-form-input>
        <span v-show="veeErrors.has('phone')" class="invalid-feedback-select">{{ veeErrors.first('phone') }}</span>
        <button
          type="button"
          class="text-center btn btn-success w-100 font-weight-bold font-size-18 mt-4"
          :disabled="true"
          @click="onRequestRecover"
        >Recuperar minha senha</button>
      </b-form-group> -->
    </div>
    <div class="text-center font-size-18 pt-4 mb-auto">
      <router-link :to="{ name: 'SignIn' }" class="font-weight-bold text-blue">
        <i class="fa fa-arrow-left align"></i> Fazer Login
      </router-link>
    </div>
    <div class="mt-auto pb-5 pt-5">
      <div class="text-gray-4 text-center">© {{ year }} Zeon Solutions. Todos os direitos reservados.</div>
    </div>
  </div>
</template>

<script>
import MsgEmailSent from './msgEmailSent'
import AccountRepository from '@/shared/http/repositories/access/account'
import { IsSuccessCode } from '@/shared/utils/API'
import { toast, loading } from '@/shared/utils/UIHelper'

export default {
  name: 'RecoveryPassword',
  components: {
    [MsgEmailSent.name]: MsgEmailSent
  },
  data: () => ({
    sent: {},
    showMessage: false,
    year: new Date().getFullYear()
  }),
  methods: {
    onRequestRecover() {
      loading.push()
      AccountRepository.RecoveryPassword(this.sent)
        .then((res) => {
          if (!IsSuccessCode(res)) return Promise.reject()
            
          loading.pop()
          toast.success('Solicitação de redefinição de senha enviada com sucesso.')
          this.showMessage = true
          
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao solicitar redefinição de senha.')
        })
    },

    // validateState(ref) {
    //   if (
    //     this.veeFields[ref] &&
    //     (this.veeFields[ref].dirty || this.veeFields[ref].validated)
    //   ) {
    //     return !this.veeErrors.has(ref)
    //   }
    //   return null
    // }
  }
}
</script>
<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>
