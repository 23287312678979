<template>
  <div class="full-screen" id="foooo">
    <div class="content-wrapper effect5">
      <div class="container-fluid">
        <h6 class="text-center success-msg">
          <span>
            <i class="fa fa-check" style="color: #3f94fc"></i>
          </span>
          <span>
            Email enviado
          </span>
        </h6>
        <p class="text-center pt-4">
          Enviamos um email para você com um link para que você possa redefinir a sua senha.
        </p>
        <p class="text-center mbt-50">
          <i class="fa fa-lightbulb-o" style="color: #e2e200"></i>
          <b>Dica:</b> Você pode fechar esta aba do seu navegador.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MsgEmailsent'
}
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

.full-screen {
  transition: 1s;
  z-index: 9999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,.95);

  .content-wrapper {
    padding: 50px;
    max-width: 80%;
    min-width: 50%;
    min-height: 40%;
    max-height: 80%;
    background-color: rgba(255,255,255,1);
    border: solid 1px #ccc;

    h6 {
      &.success-msg {
        font-family: 'Lato Light', sans-serif;
        font-weight: lighter;
        color: blue;
      }
    }

    p {
      font-size: 1.4em;

      &.user-icon {
        font-size: 1em;
      }
    }

    @media only screen and (max-width: $xs-min-width) {
      padding: 25px;
      max-height: 90%;

      h1 {
        font-size: 1.5em;
      }

      h6 {
        font-size: 1em;
      }

      p {
        font-size: 0.9em;
      }

      button {
        display: block;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 16px;
      }
    }
  }

  .effect5
  {
    position: relative;
  }
  .effect5:before, .effect5:after
  {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 25px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #777;
    -webkit-box-shadow: 0 35px 20px #777;
    -moz-box-shadow: 0 35px 20px #777;
    box-shadow: 0 35px 20px #777;
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  .effect5:after
  {
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    -o-transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    transform: rotate(8deg);
    right: 10px;
    left: auto;
  }
}
</style>